import React from 'react'

export type Props = {
  labelText: string
  children: React.ReactNode
}

export const Label: React.FC<Props> = ({ labelText, children }) => {
  return (
    <label>
      <span>{labelText}</span>
      {children}
    </label>
  )
}
