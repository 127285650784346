import React from 'react'

import styles from './prompt-item.module.scss'

type Props = {
  text: string
  handleClickItem: (text: string) => void
}

const PromptItem = (props: Props) => {
  const { text, handleClickItem } = props

  return (
    <li className={styles.promptItem} onClick={() => handleClickItem(text)}>
      {text}
    </li>
  )
}

export default PromptItem
