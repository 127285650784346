import { createContext } from 'react'

export const authContext = createContext({
  authenticated: false,
  user: null,
  accessToken: '',
  refreshToken: '',
  tokenGotTime: 0,
  login: () => {},
  logout: () => {},
  register: () => {},
  getUserData: () => {},
})

export const AuthProvider = authContext.Provider
export const AuthConsumer = authContext.Consumer
