import React from 'react'
import styles from './layout.module.scss'
import { UiTooltipsModal } from '../ui-tooltips-modal'
import { Container } from 'reactstrap'

const Layout = (props) => {
  const { children } = props

  return (
    <div className={styles.layout}>
      <Container className={styles.container} fluid>
        {children}
      </Container>
      <UiTooltipsModal />
    </div>
  )
}

export default Layout
