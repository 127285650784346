import React, { useState } from 'react'
import { format } from 'date-fns'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import 'react-datepicker/dist/react-datepicker.css'
import './date-input.scss'
import calendar from './images/calendar.svg'

registerLocale('ru', ru)

export interface DateInputInputProps {
  handleChange?: (date: any, name: string) => void
  date?: any
  name: string
  labelText?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  value: Date
  dateFormat?: string
  inputClassName?: string
  style?: React.CSSProperties
}

export const DateInput: React.FC<DateInputInputProps> = ({
  handleChange,
  date,
  name,
  labelText,
  placeholder,
  disabled,
  required,
  value,
  dateFormat,
  inputClassName,
  style,
}) => {
  const [selectedDate, setSelectedDate] = useState(date || (placeholder ? '' : new Date()))

  const ExampleCustomInput = (data) => {
    return (
      <div className='datepicker-trigger' onClick={data.onClick} style={style}>
        <input
          className={`datepicker-trigger__input ${inputClassName}`}
          readOnly
          type='text'
          value={format(new Date(value), dateFormat)}
          onFocus={data.onFocus}
          onBlur={data.onBlur}
          onKeyDown={data.onKeyDown}
        />
        <img src={calendar} className='datepicker-trigger__icon' />
      </div>
    )
  }

  const onChange = (date) => {
    setSelectedDate(date)
    handleChange(date, name)
  }

  return (
    <div>
      {labelText && <div className='datepicker-label'>{labelText}</div>}
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        disabled={disabled}
        required={required}
        customInput={<ExampleCustomInput />}
        locale='ru'
        dateFormat={dateFormat || 'd MMMM yyyy'}
        placeholderText={placeholder}
        openToDate={value ? new Date(value) : new Date()}
      />
    </div>
  )
}
