import React from 'react'
import classNames from 'classnames'
import { BUTTON_TYPE } from './constants/constants'
import style from './button.module.scss'

export type Props = {
  children?: React.ReactNode
  onClick: (event) => void
  type?: string
  block?: boolean
  className?: string
  disabled?: boolean
  restProps?: {
    [x: string]: any
  }
}

export const Button: React.FC<Props> = ({
  children,
  onClick,
  type = 'primary',
  block = false,
  className = '',
  disabled = false,
  ...restProps
}) => {
  switch (type) {
    case 'buttonFunctionalConstructorItem':
      return (
        <div
          className={classNames(style.btn, className, style[BUTTON_TYPE[type]], { 'btn-block': block })}
          onClick={onClick}
          {...restProps}
        >
          {children}
        </div>
      )
    case type:
      return (
        <button
          className={classNames(style.btn, className, style[BUTTON_TYPE[type]], { 'btn-block': block })}
          onClick={onClick}
          disabled={disabled}
          {...restProps}
        >
          {children}
        </button>
      )
  }
}
