import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Portal } from 'components'

import { Tooltip } from './components/tooltip'
import { Actions } from './redux/actions'
import './ui-tooltips-modal.scss'

export const UiTooltipsModal = () => {
  const tooltips = useSelector((state) => state.tooltips.list)
  const { deleteTooltip } = Actions
  const dispatch = useDispatch()

  const onTooltipClose = (id) => {
    dispatch(deleteTooltip(id))
  }

  return (
    <Portal isTooltipPortal>
      <div className='ui-tooltips-modal'>
        {tooltips.map((tooltip, index) => (
          <Tooltip
            key={tooltip.id}
            id={tooltip.id}
            index={index}
            text={tooltip.text}
            type={tooltip.tooltipType}
            handleClose={onTooltipClose}
          />
        ))}
      </div>
    </Portal>
  )
}
