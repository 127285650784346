import React from 'react'
import classnames from 'classnames'

import { Tags } from 'ui-kit'

import styles from './prompts.module.scss'

const Prompts = (props) => {
  const { prompts, onAddTagToList, onRemoveTagQuickly, drop } = props

  return (
    <div className={drop ? classnames(styles.prompts, styles.promptsDrop) : styles.prompts}>
      <p className={styles.info}>Выберете тег или создайте новый</p>
      <div className={styles.promptsListWrapper}>
        <ul className={styles.promptsList}>
          {!!prompts.length &&
            prompts.map((tag) => (
              <li className={styles.prompt} key={tag.name}>
                <Tags.TagPrompt tag={tag} handleOnAddTagToList={onAddTagToList} />
                {!tag.new && <span className={styles.basketIcon} onClick={() => onRemoveTagQuickly(tag.name)} />}
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default Prompts
