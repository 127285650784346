import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import styles from './input-image.module.scss'

type Props = {
  image?: string
  className?: string
  preview: boolean
  note?: string
  name?: string | null
  dataImage: File
  onChangeImage: (value: string | ArrayBuffer, name: string, data: File) => void
}

export const InputImage = (props: Props) => {
  const { onChangeImage, className, preview, note, name, image, dataImage } = props

  const [defaultImage, setDefaultImage] = useState(null)
  const [defaultName, setDefaultName] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultData, setDefaultData] = useState(null)

  const handleChangeImage = (image: string | ArrayBuffer, name: string, data: File) => {
    onChangeImage && onChangeImage(image, name, data)
  }

  useEffect(() => {
    setDefaultImage(image)
    setDefaultName(name)
    setDefaultData(dataImage)
  }, [image, dataImage])

  return (
    <label className={className ? classnames(styles.imageLabel, className) : styles.imageLabel}>
      <input
        type='file'
        className={styles.loadInput}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])

            const data = e.target.files[0]
            const { name } = data

            reader.onload = (e) => {
              const image = e.target.result
              handleChangeImage(image, name, data)
            }
            e.target.value = ''
          }
        }}
        name={name || setDefaultName.name}
        accept='image/*'
      />

      <div className={styles.loadImage}>
        {preview && (image || defaultImage) && (
          <img src={image || defaultImage} alt='logo' className={styles.imagePreview} />
        )}
        <span className={styles.loadText}>{defaultName || note}</span>
      </div>
    </label>
  )
}
