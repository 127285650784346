import React from 'react'

import styles from './tag-prompt.module.scss'

type Props = {
  handleOnAddTagToList: (tagName: string) => void
  tag: {
    name: string
    new: boolean
  }
}

const TagPrompt = (props: Props) => {
  const { tag, handleOnAddTagToList } = props

  return (
    <span className={styles.tagWrapper} onClick={() => handleOnAddTagToList(tag.name)}>
      {tag.new && <span className={styles.createTag}>Создать</span>}
      <span className={styles.tag}>{tag.name}</span>
    </span>
  )
}

export default TagPrompt
