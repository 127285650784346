import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'components/app'
import 'bootstrap/scss/bootstrap.scss'
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: [
      'Manrope:300,400,500,700, 800',
      'Roboto',
      'Open Sans',
      'Montserrat: 400,500',
      'Oswald',
      'Lora',
      'PT Serif',
      'Comfortaa',
      'Poiret One',
      'Old Standard TT',
      'sans-serif',
      'Raleway:400,500,600,700',
      'NotoSans: 700',
    ],
  },
})

ReactDOM.render(<App />, document.getElementById('root'))
