import React, { Component } from 'react'
import './tabs.scss'
import { TabsHeader } from './tabs-header'
import { TabsContent } from './tabs-content'

export class Tabs extends Component {
  state = {
    activeTab: this.props.activeTab || 0,
  }

  setActiveTab = (id) => this.setState({ activeTab: id })

  getTabsTitles = () => this.props.tabList.map((item) => item.title)

  render() {
    const { activeTab } = this.state
    const { tabList, styles } = this.props
    const tabsTitles = this.getTabsTitles()
    const classTabsHeader = this.props.classTabsHeader || ''

    return (
      <div className='tabs'>
        <TabsHeader
          handleClick={this.setActiveTab}
          list={tabsTitles}
          activeTab={activeTab}
          classTabsHeader={classTabsHeader}
        />
        <TabsContent content={tabList[activeTab].content} styles={styles && styles.wrapper} />
      </div>
    )
  }
}
