import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const Phone = ({
  id,
  className = '',
  inputClass = '',
  dropdownClass = '',
  buttonClass = '',
  containerClass = '',
  errorsClassName = '',
  placeholder = '',
  labelClass = '',
  labelText,
  errorText,
  name,
  dropdownStyle = { bottom: 35 },
  value,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = () => {},
}) => {
  const handleChange = (value) => onChange && onChange({ target: { name, value } })
  const handleFocus = (value) => onFocus && onFocus({ target: { name, value } })
  const handleBlur = (value) => onBlur && onBlur({ target: { name, value } })
  const handleKeyDown = (value) => onKeyDown && onKeyDown({ target: { name, value } })

  return (
    <div className={`profile-field__phone-wrapper ${className}`}>
      <label className={labelClass} htmlFor={id}>
        {labelText}
      </label>
      <PhoneInput
        value={value || ''}
        country='ru'
        inputClass={inputClass}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        enableSearch
        inputProps={{
          id,
        }}
        dropdownStyle={dropdownStyle}
        containerClass={containerClass}
        dropdownClass={dropdownClass}
        buttonClass={buttonClass}
      />
      {errorText && <div className={`profile-field__errors ${errorsClassName}`}>{errorText}</div>}
    </div>
  )
}
