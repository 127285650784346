import React from 'react'

export const Password = ({
  id,
  name,
  placeholder,
  labelText,
  errorText,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  className = '',
  errorsClassName = '',
  inputProps,
  value,
}) => {
  return (
    <div className={`profile-field__wrapper ${className}`}>
      <label htmlFor={id}>{labelText}</label>
      <input
        value={value || ''}
        id={id}
        type='password'
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        {...inputProps}
      />
      {errorText && <div className={`profile-field__errors ${errorsClassName}`}>{errorText}</div>}
    </div>
  )
}
