import React, { useEffect, useState } from 'react'
import styles from './input-color.module.scss'

type Props = {
  value: string
  onChange: (value: string) => void
}

export const InputColor = (props: Props) => {
  const { onChange, value } = props
  const [defaultValue, setDefaultValue] = useState(value)

  const handleColorChange = (value: string) => {
    setDefaultValue(value)
    onChange(value)
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  return (
    <label className={styles.labelColor}>
      <input
        onChange={(e) => handleColorChange(e.target.value)}
        className={styles.inputColor}
        type='color'
        value={defaultValue}
      />
    </label>
  )
}
