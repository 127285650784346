import React, { ChangeEvent, useEffect, useState } from 'react'
import classnames from 'classnames'
import './toggler.scss'

export type TogglerInputProps = {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
  id?: string
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
}

export const Toggler = (props: TogglerInputProps) => {
  const { checked, onChange, name, id, style, className, disabled = false } = props
  const [defaultValue, setDefaultValue] = useState(checked)

  useEffect(() => {
    setDefaultValue(checked)
  }, [checked])

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(e.target.checked)
    onChange(e)
  }

  return (
    <label className={className ? classnames('toggler', className) : 'toggler'} style={style}>
      <input
        type='checkbox'
        disabled={disabled}
        checked={defaultValue}
        onChange={handleCheckChange}
        name={name}
        id={id}
      />
      <span />
    </label>
  )
}
