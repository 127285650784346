import React, { forwardRef, Ref } from 'react'
import classnames from 'classnames'

import styles from './tag-toggle.module.scss'

type Props = {
  tag: {
    name: string
    active: boolean
  }
  handleToggleActive: (tagName: string) => void
}

const TagToggle = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { tag, handleToggleActive } = props

  return (
    <div
      className={tag.active ? classnames(styles.tag, styles.tagActive) : styles.tag}
      onClick={() => handleToggleActive(tag.name)}
      ref={ref}
    >
      {tag.name}
    </div>
  )
})

export default TagToggle
