import React from 'react'
import { Button } from '../../../../button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SelectedItem = ({ item, onDelete }) => {
  return (
    <span className='d-flex justify-content-between selected-item'>
      <span>{item}</span>
      <Button type='icon' onClick={onDelete}>
        <FontAwesomeIcon icon={['fas', 'times']} color='#666666' size='sm' />
      </Button>
    </span>
  )
}
