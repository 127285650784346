class ApiException {
  constructor(message) {
    this.message = message
    this.name = 'ApiException'
  }
}

export class ApiClient {
  // constructor(apiBase, auth = true) {
  constructor(apiBase) {
    this.headers = {
      'Content-Type': 'application/json',
    }

    this.fetch = async (...args) => {
      // const token = getToken()
      // if (auth && token) {
      //   this.headers.Authorization = `JWT ${token}`
      // }
      return await fetch(...args)
    }

    this._apiBase = apiBase
  }

  errorHandler = (json) => {
    if (json.error) {
      throw new ApiException(json.error)
    }

    if (json.non_field_errors) {
      throw new ApiException(json.non_field_errors)
    }

    if (json.detail) {
      throw new ApiException(json.detail)
    }

    throw new ApiException('Unrecognized api error')
  }

  get = async ({ endpoint, params }) => {
    try {
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'GET',
        headers: this.headers,
        ...params,
      })
      const json = await res.json()

      if (!res.ok) {
        this.errorHandler(json)
      }

      return json
    } catch (e) {
      console.error(`Error on api.get: ${e}`)
      throw new ApiException(e.message)
    }
  }

  delete = async ({ endpoint, data, params }) => {
    try {
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'DELETE',
        headers: this.headers,
        body: JSON.stringify(data),
        ...params,
      })
      const json = await res.json()

      if (!res.ok) {
        this.errorHandler(json)
      }

      return json
    } catch (e) {
      console.error(`Error on api.delete: ${e}`)
      throw new ApiException(e.message)
    }
  }

  post = async ({ endpoint, data, params }) => {
    try {
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(data),
        ...params,
      })
      const json = await res.json()

      if (!res.ok) {
        if (json.error) {
          throw Error(json.error)
        }

        throw Error(json.non_field_errors)
      }

      return json
    } catch (e) {
      console.error(`Error on api.post: ${e}`)
      throw new ApiException(e.message)
    }
  }

  put = async ({ endpoint, data, params }) => {
    try {
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'PUT',
        headers: this.headers,
        body: JSON.stringify(data),
        ...params,
      })
      const json = await res.json()

      if (!res.ok) {
        this.errorHandler(json)
      }

      return json
    } catch (e) {
      console.error(`Error on api.put: ${e}`)
      throw new ApiException(e.message)
    }
  }

  patch = async ({ endpoint, data, params }) => {
    try {
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'PATCH',
        headers: this.headers,
        body: JSON.stringify(data),
        ...params,
      })
      const json = await res.json()

      if (!res.ok) {
        this.errorHandler(json)
      }

      return json
    } catch (e) {
      console.error(`Error on api.put: ${e}`)
      throw new ApiException(e.message)
    }
  }

  sendFormData = async ({ endpoint, data }) => {
    try {
      // const token = getToken()
      const res = await this.fetch(`${this._apiBase}${endpoint}/`, {
        method: 'POST',
        // headers: {
        //   Authorization: `JWT ${token}`,
        // },
        body: data,
      })
      const json = await res.json()

      if (!res.ok) {
        this.errorHandler(json)
      }

      return json
    } catch (e) {
      console.error(`Error - ${e}`)
      return { type: 'error', message: e.message }
    }
  }
}
