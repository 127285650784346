import { toJson } from '../components/utils'
import { routes } from './routes'
import { API_BASE, SECOND_API_BASE } from '../components/global-constants'
import { localStorageKeys } from 'services/localstorageKeys'

export class GetSecretService {
  _apiBase = SECOND_API_BASE

  get = async ({ endpoint, params }) => {
    const res = await fetch(`${this._apiBase}${endpoint}`, {
      method: 'GET',
      ...params,
    })
    const { statusText, status } = res
    if (status === 200) {
      return await res.json()
    } else {
      return { statusText, status }
    }
  }

  sendRequest = async (url, method, data) => {
    const user = localStorage.getItem(localStorageKeys.user)
    const accessToken = localStorage.getItem('accessToken')
    const token = accessToken || user ? JSON.parse(user).token : null
    const headers = {
      Authorization: token && `JWT ${token}`,
      'Content-Type': 'application/json',
    }
    const route = `${this._apiBase}${url}`
    const params = {
      method,
      headers,
    }
    if (method !== 'GET') {
      params.body = data
    }
    const res = await fetch(route, params)
    if (res.ok) {
      return await res.json()
    } else if (res.status === 404) {
      return 'access-denied'
    }
  }

  fetchData = async (payload, data = {}) => {
    const { action, id, routeType, slug } = payload
    const { url, method, firstUrl, secondUrl } = routes[action]
    const dataToSend = toJson(data)
    const twoUrlWithId = `${firstUrl}${id}${secondUrl}`
    const urlIdWidthSlug = `${url}${id}/${slug}/`
    const urlSlug = `${url}${slug}/`
    const urlId = `${url}${id}/`
    const defaultUrl = id ? (routeType ? twoUrlWithId : slug ? (id !== 'no' ? urlIdWidthSlug : urlSlug) : urlId) : url
    return await this.sendRequest(defaultUrl, method, dataToSend, action)
  }

  sendFormData = async (url, method, body) => {
    const res = await fetch(url, { method, body })

    return await res.json()
  }

  uploadImage = async (payload, photo) => {
    const { action, id } = payload
    const { url, method } = routes[action]
    const finalUrl = `${API_BASE}${url}user${id}/`
    return await this.sendFormData(finalUrl, method, photo)
  }
}
