const {
  REACT_APP_API_PREFIX,
  REACT_APP_API_PROD,
  REACT_APP_API_DEV,
  REACT_APP_MY_ENV,
  REACT_APP_FRONT_DEV,
  REACT_APP_FRONT_PROD,
  REACT_APP_FRONT_PREFIX,
} = process.env

export const REDIRECT_URL =
  REACT_APP_MY_ENV === 'production'
    ? `${REACT_APP_FRONT_PROD}/${REACT_APP_FRONT_PREFIX}`
    : `${REACT_APP_FRONT_DEV}/${REACT_APP_FRONT_PREFIX}`

export const API_BASE =
  REACT_APP_MY_ENV === 'production'
    ? `${REACT_APP_API_PROD}/${REACT_APP_API_PREFIX}`
    : `${REACT_APP_API_DEV}/${REACT_APP_API_PREFIX}`

export const SECOND_API_BASE =
  REACT_APP_MY_ENV === 'production'
    ? `${REACT_APP_API_PROD}/${REACT_APP_API_PREFIX}`
    : `${REACT_APP_API_DEV}/${REACT_APP_API_PREFIX}`

export const SERVER = REACT_APP_MY_ENV === 'production' ? REACT_APP_API_PROD : REACT_APP_API_DEV

export const USER_TYPES = {
  secretGuest: 'secret-guest',
  business: 'business',
}
export const GENDERS = ['male', 'female']
export const FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  USER_TYPE: 'type',
  FIRST_NAME: 'first_name',
  POSITION: 'position',
  PHONE: 'phone',
  LAST_NAME: 'last_name',
  BIRTHDAY: 'birthday',
  ADDRESS: 'address',
  GENDER: 'sex',
}

export const REGISTER_FIELDS = {
  'secret-guest': ['email', 'password', 'type', 'first_name', 'phone', 'last_name', 'birthday', 'address', 'sex'],
  business: ['email', 'password', 'type', 'first_name', 'position', 'phone'],
}

export const CHECK_INDUSTRIES = [
  'Автосалон',
  'Агентство недвижимости',
  'АЗС',
  'Банк (Финансовые учреждения)',
  'Ресторан и кафе',
  'Закусочные и фаст-фуды',
  'Мебельные салоны',
  'Магазины одежды и обуви',
  'Магазины электроники',
  'Парфюмерия, косметика',
  'Салоны красоты (Мед. учреждения)',
  'Супермаркеты',
  'Страховые компании',
  'Ювелирные салоны',
  'Кинотеатр, боулинг',
  'Книжный магазин',
  'Салон сотовой связи',
  'Оптика',
  'Аптека',
  'Новостройка',
  'Гостиницы',
  'Туристические агентства',
  'Спортивные клубы',
  'Колл-центры',
  'Государственные учреждения',
  'Другое',
]

export const ANONIM_PHOTO_PATH = 'media/images/user97/6755a3f5-c62a-4741-b755-314f7df5aeb3_anonim.jpg'

export const MODAL_WIDTH = 975
