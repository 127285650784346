import React from 'react'

export const PollTypeOneFromListIcon = () => (
  <svg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='24' rx='3' fill='#8F63ED' />
    <path
      d='M16.9167 6.58333L18.1667 7.83333L20.25 5.75'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.9167 11.5833L18.1667 12.8333L20.25 10.75'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.9167 16.5833L18.1667 17.8333L20.25 15.75'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M23.1667 7H30.6667' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23.1667 12H30.6667' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23.1667 17H30.6667' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
