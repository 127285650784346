import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { Label } from '../../label'
import './phone-input.scss'

export const Phone = ({ defaultCountry, labelText, ...props }) => {
  const input = <PhoneInput country={defaultCountry} {...props} />

  return labelText ? <Label labelText={labelText}>{input}</Label> : input
}
