import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '../../../button'

export const Controls = ({ isEdited, save, discard, edit }) => {
  return (
    <div className='editable-controls'>
      {isEdited ? (
        <>
          <Button type='icon' onClick={edit}>
            <FontAwesomeIcon icon={['far', 'edit']} color='#666666' size='1x' />
          </Button>
        </>
      ) : (
        <>
          <Button type='icon' onClick={save}>
            <FontAwesomeIcon icon={['far', 'check']} color='#666666' size='1x' />
          </Button>
          <Button type='icon' onClick={discard}>
            <FontAwesomeIcon icon={['fas', 'times']} color='#666666' size='1x' />
          </Button>
        </>
      )}
    </div>
  )
}
