import React, { Component } from 'react'
import { SingleSelect } from './single-select'
import { MultipleSelect } from './multiple-select'
import { SelectFont } from './select-font'

export class Select extends Component {
  static Single = SingleSelect
  static Multiple = MultipleSelect
  static SelectFont = SelectFont

  render() {
    return <SingleSelect />
  }
}
