import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { Label } from '../../label'
import { SelectOutput } from '../parts/select-output'
import { Dropdown } from '../parts/dropdown'
import '../select.scss'

export const SingleSelect = (props) => {
  const [itemsList, setItemsList] = useState([])
  const [selectItem, setSelectItem] = useState(-1)
  const [isOpened, setIsOpened] = useState(false)
  const [inputSelected, setInputSelected] = useState(false)
  const SelectOutputRef = useRef()

  const {
    selectedItem,
    itemList = [],
    values = [],
    handleGetValue,
    onChange,
    onChangeInputField,
    name = '',
    labelText,
    inputId,
    disabled,
    styles,
    type,
    checkPossibilityToChange,
  } = props

  useEffect(() => {
    setItemsList([...itemList].sort((a, b) => a - b))
    setSelectItem(selectedItem)
    handleGetValue && handleGetValue(values[selectedItem])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  const handleClose = (event) => {
    if (event.key === 'Escape') {
      toggleDropdown()
    }
  }

  const onSelectItem = (id) => {
    if (checkPossibilityToChange && onChange) {
      if (onChange(id, name)) {
        setSelectItem(id)
      }
    } else if (!checkPossibilityToChange) {
      setSelectItem(id)
    }
    onChange && onChange(id, name) // TODO line 32: refactoring
    onChangeInputField && onChangeInputField(id, inputId)
    toggleDropdown()
    handleGetValue && handleGetValue(values[id])
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)

    return function cleanup() {
      document.body.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (event) => {
    // клик вне области SelectOutput закрывает его
    const path = event.path || (event.composedPath && event.composedPath())
    const findSelectOutput = path.some((item) => item === SelectOutputRef.current)

    setInputSelected(findSelectOutput)
    !findSelectOutput && setIsOpened(false)
  }
  const toggleDropdown = () => {
    setIsOpened((isOpened) => !isOpened) // TODO create two functions: openDropdown and closeDropdown
  }

  const selectedValue = itemList[selectItem]

  return (
    <div className={styles ? classnames('select', styles.wrapper) : 'select'} ref={SelectOutputRef}>
      <Label labelText={labelText}>
        <SelectOutput
          type={type}
          disabled={disabled}
          toggleDropdown={toggleDropdown}
          name={selectedValue}
          imgInvert={isOpened}
          isOpened={isOpened}
        />
      </Label>
      {isOpened && inputSelected && (
        <Dropdown itemList={itemsList} onSelectItem={onSelectItem} handleClose={handleClose} />
      )}
    </div>
  )
}
