import React, { useState, useEffect } from 'react'
import styles from './input-range.module.scss'

type Props = {
  min: string
  max: string
  step: string
  value: string
  onChange: (value: string) => void
}

export const InputRange = (props: Props) => {
  const { onChange, min, max, step, value } = props
  const [defaultValue, setDefaultValue] = useState(value)

  const handleRangeChange = (value: string) => {
    setDefaultValue(value)
    onChange(value)
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  return (
    <label className={styles.rangeLabel}>
      <input
        onChange={(e) => handleRangeChange(e.target.value)}
        type='range'
        min={min}
        max={max}
        step={step}
        value={defaultValue}
        className={styles.range}
      />
    </label>
  )
}
