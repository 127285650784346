import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react'
import classnames from 'classnames'

import { AddTagsContainer, Prompts } from './parts'

import styles from './input-tag.module.scss'

import { types } from './types'

type Props = {
  value: string
  tagsName: types.TagsName[]
  dropTop?: boolean
  promptsTag: types.PromptsTag[]
  styles?: {
    wrapper?: string
  }
  handleOnRemoveTagQuickly: types.HandleOnRemoveTagQuickly
  handleOnChange: types.HandleOnChange
  handleOnAddTagToList: types.HandleOnAddTagToList
  handleOnRemoveTagFromList: types.HandleOnRemoveTagFromList
  handleOnFocusTagInput: types.HandleOnFocusTagInput
  // eslint-disable-next-line react/no-unused-prop-types
  handleOnAddTagsToPoll?: types.HandleOnAddTagToList
  autoFocus?: boolean
}

export const InputTag = (props: Props) => {
  const {
    value,
    handleOnRemoveTagQuickly,
    handleOnAddTagToList,
    handleOnRemoveTagFromList,
    handleOnFocusTagInput,
    dropTop,
    handleOnChange,
    promptsTag,
    tagsName,
    autoFocus,
  } = props

  const [promptsDefault, setPromptsDefault] = useState<types.TagsName[]>([])
  const [defaulvalue, setDefaultValue] = useState('')
  const [tagsNameDefault, setTagsNameDefault] = useState<types.TagsName[]>([])

  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    setPromptsDefault(promptsTag)
    setDefaultValue(value)
    setTagsNameDefault(tagsName)
  }, [promptsTag, value, tagsName])

  const onChangeTagName = (value: string) => {
    setDefaultValue(value)
    handleOnChange && handleOnChange(value)
  }

  const onAddTagToList = (tagName: string) => {
    inputRef.current.focus()

    if (tagName.length) {
      if (tagsNameDefault.length) {
        tagsNameDefault.forEach((tag) => {
          if (tag.name.toLowerCase() === tagName.toLowerCase()) {
            setDefaultValue('')
          } else {
            setTagsNameDefault([...tagsNameDefault, { name: tagName }])
            setDefaultValue('')
          }
        })
      } else {
        setTagsNameDefault([{ name: tagName }])
        setDefaultValue('')
      }
    }

    handleOnAddTagToList && handleOnAddTagToList(tagName)
  }

  const onRemoveTagFromList = (tagName: string) => {
    const tagsNameCurrent = tagsNameDefault.filter((tag) => tag.name !== tagName)

    setTagsNameDefault(tagsNameCurrent)

    handleOnRemoveTagFromList && handleOnRemoveTagFromList(tagName)
  }

  const onFocusInput = () => {
    handleOnFocusTagInput && handleOnFocusTagInput()
  }

  const drop = dropTop || false

  return (
    <div
      className={
        props.styles && props.styles.wrapper ? classnames(props.styles.wrapper, styles.wrapper) : styles.wrapper
      }
    >
      <div className={drop ? classnames(styles.container, styles.containerDropTop) : styles.container}>
        <div className={styles.wrapperInput}>
          <div className={styles.promptsContainer}>
            {drop && !!promptsDefault.length && (
              <Prompts
                drop={drop}
                prompts={promptsDefault}
                onAddTagToList={onAddTagToList}
                onRemoveTagQuickly={handleOnRemoveTagQuickly}
              />
            )}
          </div>
          <div className={styles.inputContainer}>
            {!!tagsNameDefault && !!tagsNameDefault.length && (
              <AddTagsContainer tagsName={tagsNameDefault} onRemoveTagFromList={onRemoveTagFromList} />
            )}
            <input
              type='text'
              ref={inputRef}
              className={styles.input}
              value={defaulvalue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeTagName(e.target.value)}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                const { value } = e.target as HTMLInputElement

                if (e.key === 'Enter') {
                  onAddTagToList(value)
                }
              }}
              onFocus={() => {
                onFocusInput()
              }}
              autoFocus={autoFocus}
            />
          </div>
          <div className={styles.promptsContainer}>
            {!drop && !!promptsDefault.length && (
              <Prompts
                prompts={promptsDefault}
                onAddTagToList={onAddTagToList}
                onRemoveTagQuickly={handleOnRemoveTagQuickly}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
