import React from 'react'
import { Input } from 'ui-kit'

export const RadioGroup = ({
  labelText,
  name,
  onChange,
  radioTitles,
  radioValues,
  radioSelectedId,
  inputProps,
  errorText,
  className = '',
  errorsClassName = '',
}) => {
  const handleChange = (e, id) => onChange && onChange({ target: { name, value: radioValues[id] } })

  return (
    <div className={`profile-field__radio-wrapper ${className}`}>
      <label>{labelText}</label>
      <Input.RadioGroup
        name={name}
        titles={radioTitles}
        handleChange={handleChange}
        direction='row'
        selected={radioSelectedId}
        {...inputProps}
      />
      {errorText && <div className={`profile-field__errors ${errorsClassName}`}>{errorText}</div>}
    </div>
  )
}
