import React from 'react'
import classnames from 'classnames'
import { Button } from '../../../button'
import styles from './select-output.module.scss'

export const SelectOutput = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { name, toggleDropdown, disabled, isOpened, top, type } = props
  const renderSelectIcon = () => {
    if (!isOpened) {
      return (
        <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1L5 5L9 1' stroke='#151617' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      )
    } else {
      return (
        <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M9 5L5 1L1 5' stroke='#151617' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      )
    }
  }

  return (
    <div className={classnames('d-flex', 'output', { disabled }, styles.output)}>
      <div className={classnames('output-text', styles.text)}>{name}</div>
      <div className={classnames('dropdown-button', styles.button)}>
        <Button type='icon' onClick={toggleDropdown} disabled={disabled}>
          {!type && renderSelectIcon()}
          {/* type для скрытия галочки в настройках вопроса. временная заглушка */}
        </Button>
      </div>
    </div>
  )
}
