import React from 'react'

import { DateInput } from './DateInput'
import { Password } from './Password'
import { Email } from './Email'
import { Phone } from './Phone'
import { Text } from './Text'
import { WithErrors } from './WithErrors'
import { RadioGroup } from './RadioGroup'

import './profile-field.scss'

export class ProfileField extends React.Component {
  static DateInput = WithErrors(DateInput)
  static Password = WithErrors(Password)
  static Email = WithErrors(Email)
  static Phone = WithErrors(Phone)
  static Text = WithErrors(Text)
  static RadioGroup = WithErrors(RadioGroup)
}
