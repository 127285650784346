import { USER_TYPES } from '../global-constants'

export const toJson = (object) => JSON.stringify(object)

export const randomValue = () => Math.floor(Math.random() * 100000000)

const formatString = (x) => (+x < 10 ? `0${x}` : x)

export const formatDate = (date) => {
  if (!date) {
    return false
  }
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDay()
  return `${year}-${formatString(month + 1)}-${formatString(day)}`
}

export const getUserTypeId = (userType) => USER_TYPES.findIndex((item) => item === userType)
