import React, { SyntheticEvent } from 'react'
import './radio.scss'

export interface RadioInputProps {
  reverse?: boolean
  title: string
  checked?: boolean
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void
  name: string
  value?: string
  className?: string
}

export const Radio: React.FC<RadioInputProps> = ({
  reverse = false,
  title,
  checked,
  onChange,
  name = '',
  value,
  className,
}) => (
  <label className={`radio${reverse ? ' radio--reverse' : ''} ${className}`}>
    <input className='d-none' type='radio' checked={checked} onChange={onChange} name={name} value={value} />
    <span className='radio-custom' />
    <span className='radio-text'>{title}</span>
  </label>
)
