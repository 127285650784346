import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import * as Pages from 'pages/index'
import { Layout } from 'components'

export const Router = () => {
  return (
    <Layout.Layout>
      <Switch>
        <Route path='/' exact component={Pages.Home} />
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </Layout.Layout>
  )
}
