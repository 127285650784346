import React from 'react'

import { Tags } from 'ui-kit'

import styles from './add-tags-container.module.scss'

const AddTagsContainer = (props) => {
  const { tagsName, onRemoveTagFromList } = props

  return (
    <div className={styles.addTags}>
      {tagsName.map((tag, index) => (
        <Tags.Tag key={index} tag={tag} handleOnRemoveTag={() => onRemoveTagFromList(tag.name)} />
      ))}
    </div>
  )
}

export default AddTagsContainer
