import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from './root-reducer'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

let store

if (process.env.REACT_APP_DEBUG === 'true') {
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))
} else {
  store = createStore(rootReducer, applyMiddleware(...middlewares))
}

export { store }
