import React, { Component } from 'react'
import { debounce } from 'lodash'
import nextId from 'react-id-generator'
import { GetSecretService } from '../../../services'
import { DefaultTextInput } from '../default-text-input'
import './address.scss'

const getSecret = new GetSecretService()

export class AddressInput extends Component {
  state = {
    value: '',
    addressValues: null,
    didFindValues: true,
  }

  onAddressInput = debounce(() => {
    const { value } = this.state
    this.setState({ addressValues: null })

    if (!value) {
      this.setState({ addressValues: null })
    } else {
      getSecret.obtainData('checkAddress', value).then((res) => {
        const { result } = res
        if (typeof result === 'object') {
          this.setState({ addressValues: result })
        }
        // if (typeof result !== 'object'){
        //
        // } else {
        //     this.setState({addressValues: result})
        // }
      })
    }
  }, 300)

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  handleAddressSelect = (id) => {
    const { addressValues } = this.state
    this.setState({
      value: addressValues[id],
      addressValues: null,
    })
  }

  render() {
    const { ...props } = this.props
    const { addressValues, didFindValues } = this.state

    return (
      <div className='address-input'>
        <div className='address-input-wrap active'>
          {/* TODO при открытии добавлять класс active */}
          <DefaultTextInput
            value={this.state.value}
            onInput={this.onAddressInput}
            onChange={this.handleChange}
            {...props}
          />
        </div>

        {didFindValues && addressValues && (
          <ul className='address-list'>
            {addressValues.map((addr, index) => (
              <li key={nextId()} onClick={() => this.handleAddressSelect(index)}>
                {addr}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}
