import React from 'react'

export const CheckIcon = () => (
  <svg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.3335 4.99984L4.66683 8.33317L11.3335 1.6665'
      stroke='#F87361'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
