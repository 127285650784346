import React from 'react'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './checkbox.scss'

export interface CheckboxInputProps {
  id?: string
  checked?: boolean
  onToggleCheck?: (string) => void
  labelText?: string | null
  classLabel?: string
  styles?: { [className: string]: string }
}

export const CheckBox: React.FC<CheckboxInputProps> = (props) => {
  const { id = null, checked = false, onToggleCheck = () => {}, labelText = null, classLabel = '', styles } = props

  const stylesLabel = 'label-checkbox ' + classLabel
  let nameIcon: 'minus-square' | 'check-square' | 'square' = 'minus-square'

  if (checked === true) {
    nameIcon = 'check-square'
  }

  if (checked === false) {
    nameIcon = 'square'
  }

  const icon = <FontAwesomeIcon icon={['far', nameIcon]} color='#8355E2' size='lg' />

  return (
    <label
      className={styles ? classnames(stylesLabel.trim(), styles.wrapper) : stylesLabel.trim()}
      onClick={() => onToggleCheck(id)}
    >
      {icon}
      <span>{labelText}</span>
    </label>
  )
}
