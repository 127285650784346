import React, { Component } from 'react'
import { DefaultTextInput } from './default-text-input'
import { CheckBox } from './checkbox'
import { Radio } from './radio'
import { RadioGroup } from './radio-group'
import { Editable } from './editable'
import { Toggler } from './toggler'
import { PhoneInput } from './phone-input'
import { Password } from './password'
import { DateInput } from './date-input'
import { AddressInput } from './address-input'
import { InputSearchBtn } from './input-search-btn'
import { InputTag } from './input-tag'
import { InputColor } from './input-color'
import { InputRange } from './input-range'
import { InputImage } from './input-image'

import './input.scss'

export class Input extends Component {
  static Text = DefaultTextInput
  static CheckBox = CheckBox
  static Radio = Radio
  static RadioGroup = RadioGroup
  static Editable = Editable
  static Toggler = Toggler
  static PhoneInput = PhoneInput
  static InputSearchBtn = InputSearchBtn
  static Password = Password
  static Date = DateInput
  static Address = AddressInput
  static InputTag = InputTag
  static InputColor = InputColor
  static InputRange = InputRange
  static InputImage = InputImage

  render() {
    return <DefaultTextInput />
  }
}
