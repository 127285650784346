export enum BUTTON_TYPE {
  primary = 'btnPrimary',
  addImage = 'addImage',
  outline = 'btnOutlinePrimary',
  icon = 'btnIcon',
  iconBorder = 'btnIconBorder',
  buttonFunctionalConstructorItem = 'buttonFunctionalConstructorItem',
  numberOfCard = 'btnNumberOfCard',
  link = 'btnLink',
  dashedLink = 'btnLink btnLink--dashed',
}
