import React from 'react'

export const ListIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.33327 3.66667L3.33327 4.66667L4.99993 3'
      stroke='#8F63ED'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.33327 7.66667L3.33327 8.66667L4.99993 7'
      stroke='#8F63ED'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.33327 11.6667L3.33327 12.6667L4.99993 11'
      stroke='#8F63ED'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.33327 4H13.3333' stroke='#8F63ED' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.33327 8H13.3333' stroke='#8F63ED' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7.33327 12H13.3333' stroke='#8F63ED' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
