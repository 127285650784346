import React from 'react'

export const Email = ({
  id,
  name,
  placeholder,
  labelText,
  inputClass = '',
  errorText,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyPress = () => {},
  className = '',
  errorsClassName = '',
  labelClass = '',
  inputProps,
  value,
}) => {
  return (
    <div className={`profile-field__wrapper ${className}`}>
      {labelText && (
        <label className={labelClass} htmlFor={id}>
          {labelText}
        </label>
      )}
      <input
        value={value || ''}
        id={id}
        name={name}
        type='email'
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClass}
        onKeyPress={onKeyPress}
        pattern='\S+@\S+\.\S+'
        maxLength='25'
        {...inputProps}
      />
      {errorText && <div className={`profile-field__errors ${errorsClassName}`}>{errorText}</div>}
    </div>
  )
}
