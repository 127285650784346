import React, { forwardRef, Ref, CSSProperties } from 'react'
import { RemoveIcon } from '../images'
import styles from './tag.module.scss'

type Props = {
  tag: {
    name: string
  }
  handleOnRemoveTag?: () => void
  style?: CSSProperties
}

const Tag = forwardRef((props: Props, ref?: Ref<HTMLDivElement>) => {
  const { tag, handleOnRemoveTag, style } = props

  return (
    <div className={styles.tag} ref={ref} style={style}>
      <span>{tag.name}</span>
      {handleOnRemoveTag && (
        <div className={styles.remove}>
          <img src={RemoveIcon} alt='del' onClick={handleOnRemoveTag} />
        </div>
      )}
    </div>
  )
})

export default Tag
