import React from 'react'
import { SelectedItem } from './selected-item'
import './selected-group.scss'

export const SelectedGroup = ({ selectedItems, handleDeleteItem }) => {
  return (
    <div className='d-flex select__group'>
      {selectedItems.map((item, id) => (
        <SelectedItem key={item} item={item} onDelete={() => handleDeleteItem(id)} />
      ))}
    </div>
  )
}
