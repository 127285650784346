import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { useEventListener } from '../../utils'

import './component.scss'

const createElement = (tag, id) => {
  const element = document.createElement(tag)
  if (id) {
    element.id = id
  }
  return element
}

export const Portal = ({
  children,
  onClose,
  portalStyles,
  position = 'absolute',
  className,
  onClickTarget,
  isTooltipPortal,
}) => {
  const [container] = useState(createElement('div'))
  let root = document.querySelector('#portal__root')

  const detectPortalRoot = () => {
    if (!root) {
      root = createElement('div', 'portal__root')
      document.body.appendChild(root)
    }
  }

  const setPortalClasses = () => {
    classNames('portal', className)
      .split(' ')
      .forEach((clz) => container.classList.add(clz))
  }

  const setPortalStyles = () => {
    if (portalStyles) {
      Object.entries(portalStyles).forEach(([key, value]) => {
        container.style[key] = value
      })
    }
    container.style.position = position
  }

  const spawnPortal = () => {
    detectPortalRoot()
    setPortalStyles()
    setPortalClasses()
    const portalNumber = root.childElementCount
    container.style.zIndex = isTooltipPortal ? '10000' : `${1000 + portalNumber}`
    root.appendChild(container)
  }

  const removePortal = () => {
    if (onClose) {
      onClose()
    }
    container.remove()
    if (root.childElementCount === 0) {
      root.remove()
    }
  }

  const handleClickTarget = ({ target }) => onClickTarget && onClickTarget(target)

  useEffect(() => {
    spawnPortal()
    return () => {
      removePortal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  useEventListener('mousedown', handleClickTarget)

  return createPortal(children, container)
}
