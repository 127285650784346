import React from 'react'

export const PollTypeManyFromListIcon = () => {
  return (
    <svg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='48' height='24' rx='3' fill='#F87361' />
      <path
        d='M17.0001 12L22.0001 17L32.0001 7'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
