import React, { ChangeEvent, KeyboardEvent, CSSProperties, forwardRef, Ref } from 'react'
import { Label } from '../../label'
import './default-text-input.scss'

export interface TextInputProps {
  disabled?: boolean
  autoFocus?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e) => void
  onBlur?: (e) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onPaste?: (e) => void // указать тип для event
  onKeyDown?: (e) => void // указать тип для event
  placeholder?: string
  labelText?: string
  name?: string
  type?: string
  value?: string
  style?: CSSProperties
  id?: string
  readOnly?: boolean
  className?: string
}
export const DefaultTextInput = forwardRef((props: TextInputProps, inputRef: Ref<HTMLInputElement>) => {
  const {
    disabled,
    autoFocus,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    onKeyPress = () => {},
    onPaste = () => {},
    placeholder,
    labelText,
    name,
    value,
    style,
    id,
    type,
    readOnly,
    className,
  } = props

  const input = (
    <input
      autoFocus={autoFocus}
      id={id}
      value={value || ''}
      type={type || 'text'}
      autoComplete='off'
      disabled={disabled}
      readOnly={readOnly}
      ref={inputRef}
      onChange={(event) => onChange(event)}
      onFocus={() => onFocus(true)}
      onBlur={() => onBlur(false)}
      onKeyDown={(event) => onKeyDown(event)}
      onKeyPress={(event) => onKeyPress(event)}
      onPaste={(event) => onPaste(event)}
      placeholder={placeholder}
      name={name}
      style={style}
      className={className}
    />
  )

  return labelText ? <Label labelText={labelText}>{input}</Label> : input
})
