import React, { Component, SyntheticEvent } from 'react'
import { Radio } from '../radio'

export interface RadioGroupInputProps {
  titles: Array<string>
  direction?: string
  labelText?: string
  handleChange?: (event: SyntheticEvent<HTMLInputElement>, id: number) => void
  name: string
  selected: number
}

export class RadioGroup extends Component<RadioGroupInputProps> {
  state = {
    selected: 0,
  }

  onChange = (event, id) => {
    this.props.handleChange(event, id)
    this.setState({ selected: id })
  }

  componentDidMount() {
    const { selected } = this.props

    this.setState({ selected })
  }

  render() {
    const { titles, direction, labelText, name } = this.props
    const { selected } = this.state

    return (
      <div>
        {labelText && <div className='radio-label'>{labelText}</div>}
        <div className={`d-flex${direction === 'column' ? ' flex-column' : ' flex-row'}`}>
          {titles.map((title, id) => (
            <div className='mr-2' key={title}>
              <Radio
                onChange={(event) => this.onChange(event, id)}
                title={title}
                checked={id === selected}
                name={name}
                value={title}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}
