import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import './tabs-header.scss'

export const TabsHeader = ({ list, handleClick, activeTab, classTabsHeader = '' }) => (
  <ListGroup className={`tabs-header ${classTabsHeader}`}>
    {list.map((item, index) => (
      <ListGroupItem
        className={`tabs-header-item ${activeTab === index ? 'active' : ''}`}
        onClick={() => handleClick(index)}
        key={item}
      >
        {item}
      </ListGroupItem>
    ))}
  </ListGroup>
)
