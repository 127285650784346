import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorIndicator = () => {
  return (
    <div>
      Error! <br />
      Go to the <Link to='/'>Home page</Link>
    </div>
  )
}
