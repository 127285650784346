import React from 'react'

export const PollTypeDivisionIcon = () => (
  <svg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='48' height='24' rx='3' fill='#63A2ED' />
    <path d='M32 6H21' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32 12H25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M32 18H21' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16 8L20 12L16 16' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
