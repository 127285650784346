import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import Divider from '@material-ui/core/Divider'
import closed from './images/сlosed.svg'
import './panel.scss'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

export const Panel = ({ anchor, hide, open, close, children, width, addedWidth = 0 }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    close()
    setState({ ...state, [anchor]: open })
  }

  useEffect(() => {
    setState({ ...state, [anchor]: open })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, anchor])

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      style={{ width: !hide ? width + 43 + 15 : width }}
      role='presentation'
    >
      <div
        style={{
          width: !hide ? width + 43 + addedWidth : width,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!hide && (
          <div onClick={toggleDrawer(anchor, false)} className='close-btn'>
            <img src={closed} alt='no' />
          </div>
        )}
        {children}
      </div>
      <Divider />
    </div>
  )

  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer onOpen={() => {}} anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}
