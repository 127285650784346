import React, { forwardRef, Ref } from 'react'

import styles from './tag-more.module.scss'

type Props = {
  countTags: number
  handleOpenTagsListPoll: () => void
}

const TagMore = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { countTags, handleOpenTagsListPoll } = props
  return (
    <div
      className={styles.tag}
      onClick={() => {
        handleOpenTagsListPoll()
      }}
      ref={ref}
    >
      <span>Еще {countTags}</span>
    </div>
  )
})

export default TagMore
