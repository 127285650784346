import React, { useEffect, useRef } from 'react'

import closeIcon from './images/close.svg'
import errorIcon from './images/error.svg'
import warningIcon from './images/warning.svg'
import successIcon from './images/success.svg'

export const Tooltip = ({ id, index, text, type, handleClose = () => {} }) => {
  const ref = useRef()
  const savedIndex = useRef(index)

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClose(id)
    }, 5100)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectClassName = (type) => {
    switch (type) {
      case 'warning':
        return 'ui-tooltips-modal__tooltip-warning'
      case 'error':
        return 'ui-tooltips-modal__tooltip-error'
      case 'success':
        return 'ui-tooltips-modal__tooltip-success'
    }
  }

  const selectHeaderText = (type) => {
    switch (type) {
      case 'warning':
        return 'Предупреждение'
      case 'error':
        return 'Ошибка'
      case 'success':
        return 'Успех'
    }
  }

  const selectIcon = () => {
    switch (type) {
      case 'warning':
        return warningIcon
      case 'error':
        return errorIcon
      case 'success':
        return successIcon
    }
  }

  return (
    <div
      ref={ref}
      className={`ui-tooltips-modal__tooltip ${selectClassName(type)}`}
      style={{ top: 40 + 160 * savedIndex.current, right: 40 }}
    >
      <div className='ui-tooltips-modal__header'>
        <img src={selectIcon(type)} />
        <span className='ui-tooltips-modal__title'>{selectHeaderText(type)}</span>
        <button onClick={() => handleClose(id)} className='ui-tooltips-modal__close-btn'>
          <img src={closeIcon} />
        </button>
      </div>
      <div className='ui-tooltips-modal__text'>{text}</div>
    </div>
  )
}
