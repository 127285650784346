import React, { useState, useEffect, HTMLProps, ChangeEvent } from 'react'
import classnames from 'classnames'
import { DefaultTextInput } from '../default-text-input'
import { Button } from '../../button'
import { PromptItem } from './prompt-item'

import stylesCustom from './input-search-btn.module.scss'
import * as Images from './images'

export interface InputSearchBtnProps {
  value: string
  placeholder?: string
  styles?: {
    input?: string
    wrapper?: string
    button?: string
  }
  promptsList: string[]
  prompt?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleClickPrompt: (value: string) => void
  onClick: () => void
  otherProps?: HTMLProps<HTMLInputElement>
}

export const InputSearchBtn: React.FC<InputSearchBtnProps> = (props: InputSearchBtnProps) => {
  const { placeholder, styles, promptsList, onChange, value, handleClickPrompt, prompt, onClick, otherProps } = props

  const [defaultValue, setDefaultValue] = useState('')
  const [defaultListPrompts, setDefaultListPrompts] = useState([])

  useEffect(() => {
    setDefaultValue(value)
    setDefaultListPrompts(promptsList)
  }, [value, promptsList])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e)
    setDefaultValue(e.target.value)
  }

  const handleClickItem = (value: string) => {
    handleClickPrompt(value)
    setDefaultValue(value)
    setDefaultListPrompts([])
  }

  return (
    <div className={classnames(styles && styles.wrapper && stylesCustom.wrapper, styles.wrapper)}>
      <div className={classnames('text-input-btn', stylesCustom.textInputBtn)}>
        <DefaultTextInput
          placeholder={placeholder}
          className={styles && styles.input}
          value={defaultValue}
          onChange={handleChange}
        />
        <div className={classnames('search-button', stylesCustom.button, styles && styles.button)}>
          <Button type='icon' onClick={onClick} disabled={otherProps?.disabled}>
            <Images.SearchIcon />
          </Button>
        </div>
      </div>
      {prompt && (
        <div className={defaultListPrompts.length ? stylesCustom.prompt : stylesCustom.hidden}>
          <ul className={stylesCustom.promptList}>
            {defaultListPrompts.map((item, index) => (
              <PromptItem key={index} text={item} handleClickItem={handleClickItem} />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
