import React from 'react'
import editIcon from './icons/edit.png'
import copyIcon from './icons/copy.png'
import deleteIcon from './icons/delete.png'
import linkIcon from './icons/link.png'

interface IIcons {
  [x: string]: {
    icon: JSX.Element
  }
}

export const Icons: IIcons = {
  edit: {
    icon: (
      <span>
        <img src={editIcon} alt='toggle' />
      </span>
    ),
  },
  copy: {
    icon: (
      <span>
        <img src={copyIcon} alt='toggle' />
      </span>
    ),
  },
  link: {
    icon: (
      <span>
        <img src={linkIcon} alt='toggle' />
      </span>
    ),
  },
  delete: {
    icon: (
      <span>
        <img src={deleteIcon} alt='toggle' />
      </span>
    ),
  },
}
