import { combineReducers } from 'redux'
import { tooltipsReducer } from 'components/ui-tooltips-modal/redux/reducer'

const appReducer = combineReducers({
  tooltips: tooltipsReducer,
})

export const rootReducer = (state, action) => {
  return appReducer(state, action)
}
