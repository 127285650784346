import React from 'react'
import calendar from './images/calendar.svg'
import calendarError from './images/calendar-error.svg'

export const DateInput = ({
  id,
  name,
  placeholder,
  labelText,
  errorText,
  onChange = () => {},
  className = '',
  errorsClassName = '',
  containerClassName = '',
  inputClass = '',
  labelClass = '',
  inputProps,
  value,
}) => {
  const calendarIcon = errorText ? calendarError : calendar
  return (
    <div className={`profile-field__wrapper ${className}`}>
      <label className={labelClass} htmlFor={id}>
        {labelText}
      </label>
      <div className={`date-input ${containerClassName}`}>
        <input
          id={id}
          name={name}
          type='date'
          className={inputClass}
          onChange={onChange}
          // TODO: придумать как лучше это обработать
          value={value || ''}
          {...inputProps}
        />
        <img src={calendarIcon} className='date-input__icon' alt={placeholder} />
      </div>
      {errorText && <div className={`profile-field__errors ${errorsClassName}`}>{errorText}</div>}
    </div>
  )
}
