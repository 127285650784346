import React, { Component } from 'react'
import { SelectedGroup } from './selected-group'
import { Dropdown } from '../parts/dropdown'
import { SelectOutput } from '../parts/select-output'
import { Label } from '../../label'
import '../select.scss'

export class MultipleSelect extends Component {
  state = {
    isOpened: false,
    selectedItems: this.props.selectedItems || [],
    itemList: [],
  }

  componentDidMount() {
    const { itemList = [] } = this.props

    this.filterItemList(itemList)
  }

  handleClose = (event) => {
    if (event.key === 'Escape') {
      this.toggleDropdown()
    }
  }

  filterItemList = (itemList) =>
    this.setState(({ selectedItems }) => ({
      itemList: itemList.filter((item) => !selectedItems.includes(item)),
    }))

  handleDeleteItem = (id) => this.toggleItem(id, 'selectedItems', 'itemList')

  onSelectItem = (id) => {
    const { itemList } = this.state

    this.toggleItem(id, 'itemList', 'selectedItems')
    this.filterItemList(itemList)
    this.toggleDropdown()
  }

  toggleItem = (itemId, arrayFrom, arrayTo) => {
    const { onChange, name } = this.props

    this.setState(
      ({ [arrayFrom]: current, [arrayTo]: target }) => {
        const item = current[itemId]
        return {
          [arrayFrom]: [...current.slice(0, itemId), ...current.slice(itemId + 1)],
          [arrayTo]: [...target, item],
        }
      },
      () => {
        if (onChange) {
          onChange(this.state.selectedItems, name)
        }
      },
    )
  }

  toggleDropdown = () => {
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }))
  }

  render() {
    const { selectedItems, isOpened, itemList } = this.state
    const { maximumItems, labelText } = this.props

    return (
      <div className='select'>
        <Label labelText={labelText}>
          <SelectOutput
            disabled={selectedItems.length > maximumItems || !itemList.length}
            toggleDropdown={this.toggleDropdown}
            value={itemList[0]}
          />
        </Label>
        {isOpened && <Dropdown itemList={itemList} onSelectItem={this.onSelectItem} handleClose={this.handleClose} />}
        {selectedItems.length > 0 ? (
          <SelectedGroup selectedItems={selectedItems} handleDeleteItem={this.handleDeleteItem} />
        ) : null}
      </div>
    )
  }
}
