import React, { Component, createRef } from 'react'
import { DefaultTextInput } from '../default-text-input'
import { Controls } from './controls'
import './editable.scss'

export class Editable extends Component {
  inputRef = createRef()

  state = {
    oldValue: this.props.value || '',
    newValue: '',
    isEdited: false,
  }

  componentDidMount() {
    this.inputRef.current.value = this.props.value
  }

  handleChange = () => this.setState({ newValue: this.inputRef.current.value })

  enableInput = () => this.setState({ isEdited: true })

  disableInput = () => this.setState({ isEdited: false })

  handleSave = () => {
    const { newValue } = this.state
    this.inputRef.current.value = newValue

    this.setState({
      oldValue: newValue,
      newValue: '',
    })
    this.disableInput()
  }

  handleDiscard = () => {
    this.inputRef.current.value = this.state.oldValue
    this.setState({ newValue: '' })
    this.disableInput()
  }

  render() {
    const { isEdited } = this.state
    const { labelText, name, value } = this.props

    return (
      <div className='editable'>
        <DefaultTextInput
          labelText={labelText}
          disabled={!isEdited}
          inputRef={this.inputRef}
          onChange={this.handleChange}
          name={name}
          value={value}
        />
        <Controls isEdited={!isEdited} edit={this.enableInput} save={this.handleSave} discard={this.handleDiscard} />
      </div>
    )
  }
}
