import { createReducer } from 'reduxsauce'
import { Types } from './actions'
import { generate } from 'shortid'

// list = [
//   id: string,
//   tooltipType: 'error' | 'warning' | 'success',
//   text: string
// ]

const INITIAL_STATE = {
  list: [],
}

const pushTooltip = (state = INITIAL_STATE, { tooltipType, text }) => {
  const newTooltip = {
    id: generate(),
    tooltipType,
    text,
  }
  return {
    list: [...state.list, newTooltip],
  }
}

const deleteTooltip = (state = INITIAL_STATE, { id }) => {
  const newList = [...state.list]
  const deletedIndex = newList.findIndex((tooltip) => tooltip.id === id)

  newList.splice(deletedIndex, 1)

  return {
    list: newList,
  }
}

export const HANDLERS = {
  [Types.PUSH_TOOLTIP]: pushTooltip,
  [Types.DELETE_TOOLTIP]: deleteTooltip,
}

export const tooltipsReducer = createReducer(INITIAL_STATE, HANDLERS)
