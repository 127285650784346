import { keysToCamel } from 'helpers'

export const escapeNanPercent = (arg1, arg2) => {
  return arg2 !== 0 ? Math.round((arg1 / arg2) * 100) : 0
}

export const secondsToTime = (s) => {
  let hours = Math.floor(s / 3600)
  let minutes = Math.floor((s - hours * 3600) / 60)
  let seconds = Math.floor(s % 60)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return `${hours}:${minutes}:${seconds}`
}

const calculateManyFromListPoints = (question, userAnswers) => {
  const userAnswer = userAnswers.find((answer) => answer.questionId === question.questionId)

  if (!userAnswer) {
    return 0
  }

  const currentPoints = userAnswer.itemsQuestion.reduce((acc, answerId) => {
    const answer = question.items.find((answer) => answer.itemQuestionId === answerId)

    if (!answer) {
      return acc + 0
    }

    return acc + answer.points
  }, 0)

  return currentPoints
}

const calculateMediaPoints = (pointsAnswer) => {
  if (!pointsAnswer) {
    return 0
  }

  return pointsAnswer.points
}

const calculateYesNoPoints = (question, userAnswers) => {
  return userAnswers
    .filter((userAnswer) => {
      if (userAnswer?.yesNoAnswersId?.length) {
        return true
      }
    })
    .reduce((acc, userAnswer) => {
      let currentPoints = 0

      userAnswer.yesNoAnswersId.forEach((yesNoId) => {
        const findedAnswer = question.yesNoAnswers.find((item) => item.id === yesNoId)

        if (findedAnswer) {
          currentPoints += findedAnswer.points
        }
      })

      return acc + currentPoints
    }, 0)
}

const calculatePoints = (questions, userAnswers, pointsAnswer) => {
  return questions.reduce((acc, question) => {
    let currentPoints = 0

    switch (question.questionType) {
      case 'ManyFromListQuestion':
        currentPoints = calculateManyFromListPoints(question, userAnswers)
        break
      case 'MediaQuestion':
        currentPoints = calculateMediaPoints(pointsAnswer)
        break
      case 'YesNoQuestion':
        currentPoints = calculateYesNoPoints(question, userAnswers)
    }

    return acc + currentPoints
  }, 0)
}

const calculateManyFromListTotalPoints = (question) => {
  return question.items.reduce((acc, answer) => acc + answer.points, 0)
}

const calculateMediaTotalPoints = (question) => {
  return question.items[0].points
}

const calculateYesNoTotalPoints = (question) => {
  const allPointsForOneQuestion = question.yesNoAnswers.reduce((acc, possibleAnswer) => acc + possibleAnswer.points, 0)
  return allPointsForOneQuestion * question.items.length
}

const calculateTotalPoints = (questions) => {
  return questions.reduce((acc, question) => {
    let currentPoints = 0

    switch (question.questionType) {
      case 'ManyFromListQuestion':
        currentPoints = calculateManyFromListTotalPoints(question)
        break
      case 'MediaQuestion':
        currentPoints = calculateMediaTotalPoints(question)
        break
      case 'YesNoQuestion':
        currentPoints = calculateYesNoTotalPoints(question)
        break
    }

    return acc + currentPoints
  }, 0)
}

const calculateAveragePercent = (surveysList) => {
  return Math.floor(surveysList.reduce((acc, survey) => acc + survey.passingPercentage, 0) / surveysList.length)
}

const calculateAverageSeconds = (surveysList) => {
  return surveysList.reduce((acc, survey) => acc + survey.fillingTime, 0) / surveysList.length
}

const calculatePlatformPercentage = (surveysList, platform) => {
  const count = surveysList.filter((survey) => survey.platform === platform).length
  const percent = Number(((count / surveysList.length) * 100).toFixed())

  return { count, percent }
}

const calculateMiddleAge = (surveysList) => {
  return Math.floor(surveysList.reduce((acc, survey) => acc + survey.age, 0) / surveysList.length)
}

const inRange = (number, range) => number >= range[0] && number <= range[1]

const calculateAgeDetails = (surveysList, details) => {
  const male = surveysList.filter((survey) => inRange(survey.age, details.range) && survey.sex === 'male').length
  const female = surveysList.filter((survey) => inRange(survey.age, details.range) && survey.sex === 'female').length

  return {
    male,
    female,
    ...details,
  }
}

const isFailedManyFromList = (question) => {
  return question.answers.some((answer) => !answer.checked && answer.checkUser)
}

const isFailedMediaQuestion = (question) => {
  return question.pointsAnswer ? question.pointsAnswer.accepted : false
}

const isFailedYesNoQuestion = (question) => {
  const findedAnswer = question.yesNoAnswers.find((item) => item.id === question.answer.yesNoAnswerId)

  if (findedAnswer) {
    return !findedAnswer.checked
  }

  return false
}

const isFailedFreeAnswerQuestion = (question) => {
  return question.pointsAnswer ? question.pointsAnswer.accepted : false
}

const extractFailings = (questions) => {
  return questions.reduce((acc, question) => {
    switch (question.questionType) {
      case 'ManyFromListQuestion':
        if (isFailedManyFromList(question)) {
          acc.push(question)
        }
        break
      case 'MediaQuestion':
        if (isFailedMediaQuestion(question)) {
          acc.push(question)
        }
        break
      case 'YesNoQuestion':
        if (isFailedYesNoQuestion(question)) {
          acc.push(question)
        }
        break
      case 'FreeAnswer':
        if (isFailedFreeAnswerQuestion(question)) {
          acc.push(question)
        }
        break
    }

    return acc
  }, [])
}

const mergeAnswers = (answer1, answer2, type) => {
  const res = { ...answer1 }

  switch (type) {
    case 'ManyFromListQuestion':
      res.itemsQuestion = answer1.itemsQuestion.concat(answer2.itemsQuestion)
      break
    case 'MediaQuestion':
      res.videoAnswer = answer1.videoAnswer.concat(answer2.videoAnswer)
      break
    case 'YesNoQuestion':
      res.videoAnswer = answer1.videoAnswer.concat(answer2.videoAnswer)
      res.yesNoAnswersId = answer1.yesNoAnswersId.concat(answer2.yesNoAnswersId)
      break
    case 'FreeAnswer':
      res.freeAnswer = answer1.freeAnswer.concat(answer2.freeAnswer)
      break
  }

  return res
}

const extractPointsAnswer = (userAnswers) => {
  const newUserAnswers = userAnswers.filter((answer) => answer.event !== 'points')
  let pointsAnswer = null

  for (let i = userAnswers.length - 1; i >= 0; i--) {
    if (userAnswers[i].event === 'points') {
      pointsAnswer = userAnswers[i]
      break
    }
  }

  return { newUserAnswers, pointsAnswer }
}

const extractUserAnswers = (userAnswers, questions) => {
  const { newUserAnswers, pointsAnswer } = extractPointsAnswer(userAnswers)

  const newAnswers = []
  const memoizedQuestionsIds = []

  newUserAnswers.forEach((answer) => {
    if (!memoizedQuestionsIds.includes(answer.questionId)) {
      newAnswers.push(answer)
      memoizedQuestionsIds.push(answer.questionId)
    } else {
      const existedAnswerIndex = newAnswers.findIndex((newAnswer) => newAnswer.questionId === answer.questionId)
      const answerType = questions.find((question) => question.questionId === answer.question.id).questionType

      switch (answerType) {
        case 'ManyFromListQuestion':
          newAnswers[existedAnswerIndex] = mergeAnswers(newAnswers[existedAnswerIndex], answer, 'ManyFromListQuestion')
          break
        case 'MediaQuestion':
          newAnswers[existedAnswerIndex] = mergeAnswers(newAnswers[existedAnswerIndex], answer, 'MediaQuestion')
          break
        case 'YesNoQuestion':
          newAnswers[existedAnswerIndex] = mergeAnswers(newAnswers[existedAnswerIndex], answer, 'YesNoQuestion')
          break
        case 'FreeAnswer':
          newAnswers[existedAnswerIndex] = mergeAnswers(newAnswers[existedAnswerIndex], answer, 'FreeAnswer')
          break
      }
    }
  })

  return { userAnswers: newAnswers, pointsAnswer }
}

const getLocaleDate = (date) => {
  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
  })
}

// const getUTCTime = (date) => {
//   return `${date.getUTCHours()}:${date.getUTCMinutes()}`
// }

const getTime = (date) => {
  return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`
}

const getLocaleDateWithTime = (date) => {
  // return `${getLocaleDate(date)} ${getUTCTime(date)}`
  return `${getLocaleDate(date)} ${getTime(date)}`
}

const calculatePassedQuestions = (questions) => {
  const isQuestionPassed = (question) => {
    switch (question.questionType) {
      case 'ManyFromListQuestion':
      case 'MediaQuestion':
      case 'FreeAnswer':
        if (question.answers.some((answer) => answer.checkUser)) {
          return true
        }
        break
      case 'YesNoQuestion':
        if (question.answer.checkUser) {
          return true
        }
        break
    }

    return false
  }

  const res = questions.reduce((acc, question) => {
    if (isQuestionPassed(question)) {
      return acc + 1
    }

    return acc
  }, 0)

  return res
}

const calculateBeautifiedFillingTime = (startTime, duringInSeconds) => {
  const dateFrom = new Date(startTime)
  const dateTo = new Date(dateFrom.getTime() + duringInSeconds * 1000)

  if (dateFrom.getDate() === dateTo.getDate()) {
    // return `${getLocaleDateWithTime(dateFrom)} - ${getUTCTime(dateTo)}`
    return `${getLocaleDateWithTime(dateFrom)} - ${getTime(dateTo)}`
  }

  return `${getLocaleDateWithTime(dateFrom)} - ${getLocaleDateWithTime(dateTo)}`
}

const getManyFromListAnswers = (question, userAnswers) => {
  return question.items.map((item) => {
    const answers = userAnswers.find((item) => item.questionId === question.questionId)

    if (!answers) {
      return {
        ...item,
        checkUser: false,
      }
    }

    const answer = answers.itemsQuestion.find((id) => id === item.itemQuestionId)

    if (!answer) {
      return {
        ...item,
        checkUser: false,
      }
    }

    return {
      ...item,
      checkUser: true,
    }
  })
}

const getMediaListAnswers = (question, userAnswers) => {
  return question.items.map((answer) => {
    const answers = userAnswers.filter((item) => item.questionId === question.questionId)

    if (!answers) {
      return {
        ...answer,
        checkUser: false,
      }
    }

    const { videoAnswer } = answers[0]

    return {
      ...answer,
      checkUser: true,
      videoAnswer,
    }
  })
}

const getFreeAnswerListAnswers = (question, userAnswers) => {
  return question.items.map((item) => {
    const answer = userAnswers.find((item) => item.questionId === question.questionId)
    const checkAnswers = answer?.textAnswer?.find((item) => {
      if (item) {
        return !!item[0]
      }
    })
    if (!checkAnswers) {
      return {
        ...item,
        checkUser: false,
        answer,
      }
    }
    return {
      ...item,
      checkUser: true,
      answer,
    }
  })
}

export const isNotEmpty = (item) => item?.length > 0

const getAnswers = (question, userAnswers) => {
  switch (question.questionType) {
    case 'ManyFromListQuestion':
      return getManyFromListAnswers(question, userAnswers)
    case 'MediaQuestion':
      return getMediaListAnswers(question, userAnswers)
    case 'FreeAnswer':
      return getFreeAnswerListAnswers(question, userAnswers)
  }
}

const transformQuestions = (survey, userAnswers, pointsAnswer) => {
  const finalQuestions = []
  survey.questions.forEach((question) => {
    switch (question.questionType) {
      case 'FreeAnswer':
      case 'ManyFromListQuestion':
      case 'MediaQuestion':
        finalQuestions.push({
          ...question,
          pointsAnswer,
          surveyId: survey.surveyId,
          pollId: survey.pollId,
          answers: getAnswers(question, userAnswers),
        })
        break
      case 'YesNoQuestion':
        question.items.forEach((internalQuestion, index) => {
          const userAnswer = userAnswers.find((userAnswer) => userAnswer.questionId === question.questionId)

          finalQuestions.push({
            ...question,
            ...internalQuestion,
            pointsAnswer,
            surveyId: survey.surveyId,
            pollId: survey.pollId,
            answer: {
              yesNoAnswerId: isNotEmpty(userAnswer.yesNoAnswersId) ? userAnswer.yesNoAnswersId[index] : null,
              videoAnswer: isNotEmpty(userAnswer.videoAnswer) ? userAnswer.videoAnswer[index] : null,
              photoAnswer: isNotEmpty(userAnswer.photoAnswer) ? userAnswer.photoAnswer[index] : null,
              textAnswer: isNotEmpty(userAnswer.textAnswer) ? userAnswer.textAnswer[index] : null,
              audioAnswer: isNotEmpty(userAnswer.audioAnswer) ? userAnswer.audioAnswer[index] : null,
              checkUser: isNotEmpty(userAnswer.yesNoAnswersId) ? !!userAnswer.yesNoAnswersId[index] : false,
            },
          })
        })
        break
    }
  })
  return finalQuestions
}

export const serializeData = (surveysList) => {
  const serializedList = keysToCamel(surveysList).map((survey) => {
    const { userAnswers, pointsAnswer } = extractUserAnswers(survey.userAnswers, survey.questions)

    const points = calculatePoints(survey.questions, userAnswers, pointsAnswer)
    const totalPoints = calculateTotalPoints(survey.questions)
    const pointsPercentage = escapeNanPercent(points, totalPoints)

    const fillingTime = survey.userAnswers.reduce((acc, item) => acc + item.during, 0)

    const questions = transformQuestions(survey, userAnswers, pointsAnswer)

    const totalQuestions = questions.length
    const passedQuestions = calculatePassedQuestions(questions)
    const passingPercentage = escapeNanPercent(passedQuestions, totalQuestions)

    const failingsList = extractFailings(questions)
    const totalFailings = failingsList.length
    const failingPercentage = escapeNanPercent(totalFailings, totalQuestions)

    const beautifiedFillingTime = calculateBeautifiedFillingTime(survey.createdAt, fillingTime)

    return {
      ...survey,
      questions,
      totalQuestions,
      passedQuestions,
      passingPercentage,
      points,
      totalPoints,
      pointsPercentage,
      fillingTime,
      totalFailings,
      failingPercentage,
      beautifiedFillingTime,
      failingsList,
    }
  })

  const desktop = calculatePlatformPercentage(serializedList, 'Desktop')
  const mobile = calculatePlatformPercentage(serializedList, 'Mobile')
  const other = calculatePlatformPercentage(serializedList, 'Other')
  const middleAge = calculateMiddleAge(serializedList)
  const ageRanges = [
    {
      title: 'До 18',
      range: [0, 17],
    },
    {
      title: '18 - 24',
      range: [18, 24],
    },
    {
      title: '25 - 35',
      range: [25, 35],
    },
    {
      title: '36 - 45',
      range: [36, 45],
    },
    {
      title: 'от 46',
      range: [46, 1000],
    },
  ]
  const detail = ageRanges.map((item) => calculateAgeDetails(serializedList, item))

  const audienceDataItem = {
    desktop,
    mobile,
    other,
    middleAge,
    detail,
  }

  const averagePercent = calculateAveragePercent(serializedList)
  const averageSeconds = calculateAverageSeconds(serializedList)
  const averageTime = secondsToTime(averageSeconds)

  return {
    averagePercent,
    averageTime,
    list: serializedList,
    audienceDataItem,
  }
}
