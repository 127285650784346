import React from 'react'

export const PollTypeServiceIcon = () => (
  <svg width='48' height='24' viewBox='0 0 48 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='47' height='23' rx='2.5' fill='#8478A1' stroke='#8478A1' />
    <path
      d='M25.5 5.25V8.25C25.5 8.44891 25.579 8.63968 25.7197 8.78033C25.8603 8.92098 26.0511 9 26.25 9H29.25'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.75 18.75H20.25C19.8522 18.75 19.4706 18.592 19.1893 18.3107C18.908 18.0294 18.75 17.6478 18.75 17.25V6.75C18.75 6.35218 18.908 5.97064 19.1893 5.68934C19.4706 5.40804 19.8522 5.25 20.25 5.25H25.5L29.25 9V17.25C29.25 17.6478 29.092 18.0294 28.8107 18.3107C28.5294 18.592 28.1478 18.75 27.75 18.75Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M23.25 13.5H24V16.5H24.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M24 11.25H24.0075' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
