import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { Router } from '../router'
import { ErrorBoundary } from '../error-boundary'
import { GetSecretServiceProvider } from '../react-context'
import { GetSecretService } from '../../services'
import { store } from 'store'

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas, faCoffee, faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import { far, faStar as farStar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import './app.scss'

library.add(fab, fas, far, farStar, fasStar, faCoffee, faCalendarAlt)

const getSecretService = new GetSecretService()

const App = () => {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <GetSecretServiceProvider value={getSecretService}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </GetSecretServiceProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export { App }
