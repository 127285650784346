import React, { useState, useEffect } from 'react'
import styles from './select-font.scss'

type Props = {
  value: string
  onChange: (value: string) => void
}

export const SelectFont = (props: Props) => {
  const { onChange, value } = props
  const [defaultValue, setDefaultValue] = useState(value)
  const fonts = [
    'Roboto',
    'Open Sans',
    'Montserrat',
    'Oswald',
    'Lora',
    'PT Serif',
    'Comfortaa',
    'Poiret One',
    'Old Standard TT',
  ]

  const handleFontChange = (value: string) => {
    setDefaultValue(value)
    onChange(value)
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  return (
    <select
      onChange={(e) => handleFontChange(e.target.value)}
      value={defaultValue}
      className={styles.fontSelect}
      style={{ fontFamily: defaultValue }}
    >
      {fonts.map((item, i) => {
        return (
          <option className={styles.fontOption} key={i} style={{ fontFamily: item }}>
            {item}
          </option>
        )
      })}
    </select>
  )
}
